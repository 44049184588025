<template>
  <div class="home">
    <PersonaliForm />
  </div>
</template>

<script>
import PersonaliForm from "@/components/Forms/Personali";
export default {
  name: "Personali",
  components: {
    PersonaliForm
  }
};
</script>

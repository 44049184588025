<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center">
        <!-- Nome -->
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-text-field
            v-model="personali.name"
            :rules="rules.name"
            filled
            label="Nome"
          />
        </v-col>
        <!-- Cognome -->
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-text-field
            v-model="personali.surname"
            :rules="rules.surname"
            filled
            label="Cognome"
          />
        </v-col>
        <!-- Email -->
        <v-col cols="12" md="8" sm="12" xs="12">
          <v-text-field
            v-model="personali.email"
            :rules="rules.email"
            filled
            label="Email"
          />
        </v-col>
        <!-- Phone -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-text-field
            v-model="personali.phone"
            :rules="rules.phone"
            filled
            label="Cellulare"
          />
        </v-col>
        <!-- Sesso -->
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-select
            v-model="personali.sex"
            :rules="rules.surname"
            filled
            label="Sesso"
            item-text="name"
            item-value="id"
            item-disabled="disabled"
            :return-object="false"
            :items="sesso"
            hide-selected
            persistent-hint
          />
        </v-col>
        <!-- Birtday -->
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="personali.birth.date"
                label="Data di nascita"
                append-icon="mdi-calendar"
                :rules="rules.bday"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="personali.birth.date"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- Nazione Nascita -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-text-field
            v-model="personali.birth.country"
            :rules="rules.birth_country"
            filled
            label="Nazione di nascita"
          />
        </v-col>
        <!-- Provincia Nascita -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-text-field
            v-model="personali.birth.state"
            :rules="rules.birth_state"
            filled
            label="Provincia di nascita"
          />
        </v-col>
        <!-- Città Nascita -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-text-field
            v-model="personali.birth.city"
            :rules="rules.birth_city"
            filled
            label="Città di nascita"
          />
        </v-col>
        <!-- Nazione Residenza -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-text-field
            v-model="personali.residence.country"
            :rules="rules.residence_country"
            filled
            label="Nazione di residenza"
          />
        </v-col>
        <!-- Provincia Residenza -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-text-field
            v-model="personali.residence.state"
            :rules="rules.residence_state"
            filled
            label="Provincia di residenza"
          />
        </v-col>
        <!-- Città Residenza -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-text-field
            v-model="personali.residence.city"
            :rules="rules.residence_state"
            filled
            label="Città di residenza"
          />
        </v-col>
        <!-- Indirizzo -->
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-text-field
            v-model="personali.residence.address"
            :rules="rules.residence_state"
            filled
            label="Indirizzo attuale"
          />
        </v-col>
        <v-col cols="12" md="2" sm="12" xs="12">
          <v-text-field
            v-model="personali.residence.zipcode"
            :rules="rules.residence_state"
            filled
            label="CAP"
          />
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-text-field
            v-model="personali.taxid"
            :rules="rules.residence_state"
            filled
            label="Codice fiscale"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col class="mb-6">
        <v-btn
          color="primary"
          elevation="2"
          x-large
          block
          :disabled="!valid"
          :loading="submit_loading"
          @click="submit"
        >
          Continua
        </v-btn>
        <v-alert class="mt-6" dismissible type="error" v-if="submit_error">
          <span>
            Qualcosa è andata storto... Contatta @Ventu97 su Telegram o riprova
          </span>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CollecHelper from "@/helper/collect";
export default {
  data() {
    return {
      date: null,
      menu: false,
      valid: false,
      submit_loading: false,
      submit_error: false,
      watch: {
        menu(val) {
          val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
        }
      },
      methods: {
        save(date) {
          this.$refs.menu.save(date);
        }
      },
      sesso: [
        { id: "m", name: "Maschio" },
        { id: "f", name: "Femmina" },
        { id: "none", name: "Poco", disabled: true }
      ],
      rules: {
        name: [v => !!v || "Inserisci il tuo nome"],
        surname: [v => !!v || "Inserisci il tuo cognome"],
        email: [
          v => !!v || "Inserisci la tua email",
          v => /.+@.+\..+/.test(v) || "Mail non valida"
        ],
        phone: [
          v => !!v || "Inserisci il tuo numero",
          v => /(?:^\+?)(?:[0-9] ?){6,14}[0-9]/.test(v) || "Numero non valido"
        ],
        sex: [v => !!v || "Seleziona il tuo sesso"],
        bday: [v => !!v || "Seleziona la tua data di nascita"],
        birth_country: [v => !!v || "Inserisci la tua nazione di nascita"],
        birth_state: [v => !!v || "Inserisci la tua provincia di nascita"],
        birth_city: [v => !!v || "Inserisci la tua città di nascita"],
        residence_country: [
          v => !!v || "Inserisci la tua nazione di residenza"
        ],
        residence_state: [
          v => !!v || "Inserisci la tua provincia di residenza"
        ],
        residence_city: [v => !!v || "Inserisci la tua città di residenza"],
        residence_address: [v => !!v || "Inserisci la tua via e numero civico"],
        residence_zipcode: [
          v => !!v || "Inserisci il tuo CAP",
          v => /[0-9]+/.test(v) || "CAP non valido"
        ],
        taxid: [v => !!v || "Inserisci il tuo codice fiscale"]
      }
    };
  },
  methods: {
    submit: async function() {
      if (this.submit_loading === true) {
        return false;
      }
      this.submit_loading = true;
      this.submit_error = false;
      await this.$refs.form.validate();
      if (this.valid) {
        const that = this;
        const result = CollecHelper.addUser(this.$store.state.tesseramento);
        result.then(() => {
          this.$store.commit("MutateStep", 3);
          this.$router.push("/step3");
        });
        result.catch(() => {
          that.submit_error = true;
        });
        result.finally(() => {
          that.submit_loading = false;
        });
      }
      this.submit_loading = false;
    },
    save(date) {
      this.$refs.menu.save(date);
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  computed: {
    personali: {
      get() {
        return this.$store.state.tesseramento.personali;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>

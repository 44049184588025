import api from "@/utils/api";
import axios from "axios";
class CollecHelper {
  static addUser(tesserato) {
    return axios.post(api.collector.tesserato(), {
      tesserato: tesserato
    });
  }
}

export default CollecHelper;
